import React from "react"
import Media from "react-bootstrap/Media"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import Layout from "../components/layout"
import Hero from "../components/hero"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <Container
      fluid={true}
      style={{
        paddingTop: "20px",
        paddingBottom: "20px",
        backgroundColor: "#31708e",
      }}
    >
      <Container>
        <Media id="experience">
          {/*<img*/}
          {/*  className="d-none d-sm-block"*/}
          {/*  style={{*/}
          {/*    marginRight: "1rem",*/}
          {/*    width: "10rem",*/}
          {/*    height: "10rem",*/}
          {/*  }}*/}
          {/*/>*/}
          <Media.Body>
            <h5>Experience</h5>
            <p>
              We have many years of commercial web development experience,
              working in a range of industries and organisations. We have a deep
              understanding of web technologies and a broad practical knowledge
              of producing optimal experiences for customers using a diverse
              range of devices.
            </p>
          </Media.Body>
        </Media>
      </Container>
    </Container>
    <Container
      fluid={true}
      style={{
        paddingTop: "20px",
        paddingBottom: "20px",
        backgroundColor: "#5085a5",
      }}
    >
      <Container>
        <Media id="philosophy">
          <Media.Body>
            <h5>Philosophy</h5>
            <p>
              We believe code is written for humans and strive for clarity and
              simplicity. We enjoy working in iterative development environments
              where change is embraced and feedback is frequent.
            </p>
          </Media.Body>
          {/*<img*/}
          {/*  className="d-none d-sm-block"*/}
          {/*  style={{*/}
          {/*    marginLeft: "1rem",*/}
          {/*    width: "10rem",*/}
          {/*    height: "10rem",*/}
          {/*  }}*/}
          {/*/>*/}
        </Media>
      </Container>
    </Container>
    <Container
      fluid={true}
      style={{
        paddingTop: "20px",
        paddingBottom: "20px",
        backgroundColor: "#8fc1e3",
      }}
    >
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <Card>
              <Card.Body>
                <h5>Clients</h5>
                <ul>
                  <li>The Financial Times</li>
                  <li>Equal Experts</li>
                  <li>The Home Office</li>
                  <li>O2</li>
                  <li>GLH Hotels</li>
                  <li>Tesco</li>
                  <li>Not On The High Street</li>
                </ul>
              </Card.Body>
            </Card>
            <br />
          </Col>
          <Col sm={12} md={6}>
            <Card>
              <Card.Body>
                <h5>Development services</h5>
                <ul>
                  <li>User interfaces</li>
                  <li>API and server-side</li>
                  <li>Whole websites</li>
                </ul>
              </Card.Body>
            </Card>
            <br />
          </Col>
        </Row>
      </Container>
    </Container>
  </Layout>
)

export default IndexPage
