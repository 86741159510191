import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import React from "react"

import styles from "./hero.module.css"

const Hero = () => (
  <Container fluid={true} className={styles.hero}>
    <Container>
      <h1>Interfacer</h1>
      <p className="lead font-weight-normal">
        Full-stack web development and software consulting in London
      </p>
      <Button variant="outline-light" href="/talk-to-us">
        Talk to us
      </Button>
    </Container>
  </Container>
)

export default Hero
